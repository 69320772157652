<template>
<!-- similar like {% url 'EventDetails' event.id %} -->
  <router-link :to='{ name: "EventDetails", params: {id: event.id} }'>
    <div class="event-card">
      <!-- Display data -->
      <div class="card">
        <h2>
          {{ event.title }} <small class="badge">@ {{ event.date }}</small>
        </h2>
        <ul>
          <li>Gesichetet: {{ event.location }}</li>
          <li>Verantwortlicher: {{ event.organizer }}</li>
          <li>Kategorie: {{ event.category }}</li>
        </ul>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'EventCard',
  props: {
    event: Object,
  },
}
</script>

<style scoped>
.card {
  max-width: 28rem;
  height: 100%;
  margin: 1rem auto;
  padding: 10px;
  cursor: pointer;
  transition: 0.5s;
}
.card:hover {
  box-shadow: 2px 1px 15px #aaa;
  transform: scale(1.05);
  transition: 0.5s;
}
.card h2 {
  display: flex;
  justify-content: space-between;
}
.card .badge {
  font-size: 1rem;
  align-items: bottom;
}
ul {
  list-style: none;
  padding: 0;
}
</style>
