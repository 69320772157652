import axios from 'axios'

// single Axios instance for an app
const apiClient = axios.create({
    baseURL: 'https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3',    // Basic fetch URL (e.g. if django_rest baseURL: 'localhost:8000')
    withCredentials: false,
    // for authentication & configuration
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

// define different methods to fetch different data
export default {
    getEvents() {
        return apiClient.get('/events')  // makes get request from baseURL + '/events'
    },
    // get event by id
    getSingleEvent(id) {
        return apiClient.get('/events/' + id)
    }
}