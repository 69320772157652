<template>
<!-- v-if waits until a event (data) response -->
  <div v-if="event">
    <p>{{ event.description }}</p>
  </div>
</template>

<script>
import EventService from '@/services/EventServices.js'
export default {
    props: ['id'],
  data() {
    return {
      event: null
    }
  },
  created() {
    // fetch event by id
    EventService.getSingleEvent(this.id)
      .then((response) => {
        this.event = response.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>

<style>
</style>